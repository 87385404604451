





































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TariffsTagsHelper from '../../../mixins/TariffsTagsHelper'
import { InputSetups } from '@/mixins/input-setups'
import BindChannelByCode, { BindChannelState } from '@/components/chat/BindChannelByCode.vue'
import WarningJournalGroup from '@/views/chat/journal/WarningJournalGroup.vue'
import PenaltiesJournalGroup from '@/views/chat/journal/PenaltiesJournalGroup.vue'
import OtherJournalGroup from '@/views/chat/journal/OtherJournalGroup.vue'
import TriggersJournalGroup from './TriggersJournalGroup.vue'
import UsersJournalGroup from '@/views/chat/journal/UsersJournalGroup.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    UsersJournalGroup,
    OtherJournalGroup,
    PenaltiesJournalGroup,
    WarningJournalGroup,
    BindChannelByCode,
    CenteredColumnLayout,
    PageTitle,
    NotAvailableOptionsOverlay,
    TriggersJournalGroup
  },
})
export default class Journal extends Mixins(UseFields, TariffsTagsHelper, InputSetups) {

  handleOnChannelBind(bindData: BindChannelState): void {
    if (bindData.channel_id) {
      this.$confirm({
        title: this.$t('save_success').toString(),
        content: this.$t('bind_journal_save_message').toString(),
        okText: this.$t('do_save_chat_config').toString(),
        cancelText: this.$t('pi_reject').toString(),
        onOk: () => this.$store.dispatch('saveChatConfig')
      })
    }
  }

  getBingChannelInfo(): string {
    let chatInfo = ''
    if(this.$store.state.chatState.chat.brand) {
      chatInfo = this.$t('bind_channel_code_info_2', { '0': `@${ this.$store.state.chatState.chat.brand.botname }`, '1': `https://t.me/${ this.$store.state.chatState.chat.brand.botname }` }).toString()
    } else {
      chatInfo = this.$t('bind_channel_code_info_2', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
    return chatInfo
  }
}
