var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"warning-journal-group"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': { 'model': _vm.stateChatConfigPath,
                'key': 'journal_moderation_all_ban' }
    }}}),(!_vm.$store.state.chatState.chat.config.journal_moderation_all_ban)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_censor_word' ,
                  'hasAnchor': true,}
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_stop_word',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_permitted_word',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_regexp_word' ,
                  'hasAnchor': true,}
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_flood' ,
                  'hasAnchor': true,}
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_web_links',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_self_links',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_email_links',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': { 'model': _vm.stateChatConfigPath,
                  'key': 'journal_moderation_ban_sticker',
                  'hasAnchor': true, }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_sticker_pack',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_voice_chat',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_voice' ,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_images',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_gif',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_audio' ,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_video',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_files',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_contact',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_warn',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_rtl_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_caps_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_bot_reply',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_bot_command',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_channel_reply',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_chat_reply',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_any_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_dice',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_video_note',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_message_length',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_hieroglyph_message' ,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_referral' ,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_emoji',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_channel_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_spoiler',
          'hasAnchor': true,
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }