import { render, staticRenderFns } from "./PenaltiesJournalGroup.vue?vue&type=template&id=40a13630&scoped=true&"
import script from "./PenaltiesJournalGroup.vue?vue&type=script&lang=ts&"
export * from "./PenaltiesJournalGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a13630",
  null
  
)

export default component.exports