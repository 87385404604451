var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{attrs:{"title":_vm.$t('field_bind_journal_channel_title')}},[(_vm.$store.state.chatState.chat.log_channel_id)?_c('div',{staticClass:"flex justify-between items-center"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.$store.state.chatState.chat.log_channel_id)+" ")]),_vm._v(" - "+_vm._s(_vm.$t('bind_journal_active_info_1'))+" ")]),_c('a-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.$store.dispatch('runChatAction', 'resetlogchannel')}}},[_vm._v(" "+_vm._s(_vm.$t('dellog_journal'))+" ")])],1):_c('div',[_c('a-alert',[_c('template',{slot:"description"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('bind_channel_code_info_1'))+" ")]),_c('p',{staticClass:"mt-2",domProps:{"innerHTML":_vm._s(_vm.getBingChannelInfo())}})])],2),_c('bind-channel-by-code',{staticClass:"mt-2",attrs:{"bind-code-type":"Log"},on:{"onChannelBind":_vm.handleOnChannelBind}})],1)]),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_warnings_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_warn_warn',
          'hasAnchor': true,
        }
      }}}),_c('a-alert',{staticClass:"mx-2"},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_warnings_help_message'))},slot:"message"})]),_c('warning-journal-group')],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_penalties_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'journal_moderation_ban_warn',
          'hasAnchor': true,
        }
      }}}),_c('a-alert',{staticClass:"mx-2"},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_penalties_help_message'))},slot:"message"})]),_c('penalties-journal-group')],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_other_title')}},[_c('a-alert',{staticClass:"mx-2"},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_penalties_help_message'))},slot:"message"})]),_c('other-journal-group')],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_triggers_title')}},[_c('a-alert',{staticClass:"mb-2"},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_triggers_help_message'))},slot:"message"})]),_c('triggers-journal-group')],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_journal_card_users_title')}},[_c('a-alert',{staticClass:"mb-2"},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('journal_users_help_message'))},slot:"message"})]),_c('users-journal-group')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }